.responsive-table {
    li {
      border-radius: 10px;
      padding: 1.25rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .table-header {
      background-color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
        flex-basis: auto;
        max-width: max-content;
        min-width: 5%;
    }
    .action-btn .btn{
        line-height: 1;
    }
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
      .table-row{
        
      }
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }