@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";


$theme-link-color:    #969696;
$theme-link-hover-color: #c92027;
$theme-bg-color: #c92027;
$theme-button-color: #c92027;
$theme-border-color: #c92027;

$red-400:     #c92027 !default;
$primary:       $red-400 !default;
$primary-light: lighten($primary, 10%);
$primary-dark:  darken($primary, 10%);

$white:    #fff !default;
$gray-900: #6c757d !default;
$black:    #000 !default;
$gray-border: #e4e4e4 !default;

$border: #f3f3f3;
$border-color:                $border;

$input-focus-border-color: none !default;
$input-focus-box-shadow:  none !default;
$custom-select-focus-box-shadow:  none !default;
$border-width: 1px !default;
$line-height-base: 1.5 !default;
$input-btn-padding-y: .275rem !default;
$input-btn-padding-x: .65rem !default;
$input-btn-line-height:  $line-height-base !default;
$input-padding-y: $input-btn-padding-y !default;
$input-line-height: $input-btn-line-height !default;
$input-btn-border-width: $border-width !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;
$input-height: calc(#{1.2 * 1em} + #{$input-padding-y * 2} + #{5}) !default;

$headings-font-weight: 500 !default;
$headings-color: null !default;

$input-focus-box-shadow: none;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-font-size: 1rem !default;
$btn-focus-box-shadow: none;


//$light: $white !default;

$link-color: #6c757d !default;
$link-hover-color: #c92027 !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    60: 60%,
    75: 75%,
    80: 80%,
    90: 90%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$custom-file-text: ();




